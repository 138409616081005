import {decrypt} from "../utils/encrypt";

export default (to, from, next) => {
  try {
    if (!to.meta.auth) {
      next();
    } else if (to.matched.some(record => record.meta.auth)) {
      const store = localStorage.getItem('payment-admin-type')
      if(store){
        const role = decrypt(store)
        if (role) {
          const arrayRole = to.matched.filter(x => x.meta.roles).map(x => x.meta.roles);
          if (arrayRole.every(x => x.some(r => role.includes(r)))) {
            next();
          } else {
            next('/login');
          }
        } else {
          next('/login');
        }
      }else{
        next('/login');
      }
    }
  } catch (e) {
    console.error(e);
  }
}
